import React from 'react';
import { connect } from 'react-redux';

import SEO from '../components/seo';
import AuthRoute from '../components/auth-route';
import EditShippingAddress from '../components/edit-shipping-address';
import EditDefaultCard from '../components/edit-default-card';
import ManageSubscriptions from '../components/manage-subscriptions';
import LogoutButton from '../components/logout-button';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/my-account.module.scss';

const MyAccountPage = (props) => (
    <>
        <SEO
            title='My Account'
            path='/my-account/'
            description='View your account: edit your default card, shipping info, and manage your subscriptions.'
        />
        <div className={styles.myaccount}>
            <div className={styles.content}>
                <section className={styles.header}>
                    <h1>My Account</h1>
                    {props.auth.authenticated &&
                        <p>Hello, {props.auth.claims.email}!</p>
                    }
                    <LogoutButton />
                </section>
                <div className={styles.shipCard}>
                    <EditShippingAddress />
                    <EditDefaultCard />
                </div>
                <div className={styles.subscriptions}>
                    <ManageSubscriptions />
                </div>
            </div>
        </div>
    </>
);

const mapState = (state) => ({
    auth: state.auth,
    account: state.account
});

const mapDispatch = { ...AccountActions };

export default connect(mapState, mapDispatch)(
    AuthRoute(MyAccountPage)
);
