
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import CircularProgress from '@material-ui/core/CircularProgress';

import styles from '../styles/auth-route.module.scss';

const Loading = () => (
    <div className={styles.loading}>
        <h5>Getting your account...</h5>
        <CircularProgress size={48} />
    </div>
);

const AuthRoute = (Component) => {
    const Handler = (props) => {
        useEffect(() => {
            if (props.auth.initialized && !props.auth.authenticated && typeof window !== 'undefined') {
                navigate(
                    '/login/',
                    { replace: true }
                );
            }

            if (props.auth.initialized && props.auth.authenticated && !props.account.initialized) {
                props.getAccountDetails();
            }
        }, [props]);

        if (!(props.auth.initialized && props.auth.authenticated && props.account.initialized)) {
            return (
                <Loading />
            );
        }

        return (
            <Component {...props} />
        );
    };

    return Handler;
};

export default AuthRoute;
