import React from 'react';
import { Elements } from 'react-stripe-elements';

import EditDefaultCardInner from './edit-default-card-inner';

const EditDefaultCard = (props) => (
    <Elements>
        <EditDefaultCardInner {...props} />
    </Elements>
);

export default EditDefaultCard;
