import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import StoreContext from '../context/store-context';

import * as AccountActions from '../actions/account-actions';

const LogoutButton = (props) => {
    const context = useContext(StoreContext);

    const onLogout = () => {
        props.logout();

        const { client, checkout } = context;

        context.deletePrefillInfo(client, checkout);
    };

    return (
        <Button
            type='button'
            variant='text'
            color='secondary'
            onClick={onLogout}
        >
            Logout
        </Button>
    );
}

const mapDispatch = { logout: AccountActions.logout };

export default connect(null, mapDispatch)(LogoutButton);
