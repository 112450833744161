import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { X } from 'react-feather';

import NativeSelect from './native-select';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/my-account.module.scss';

class EditShippingAddress extends Component {
    constructor(props) {
        super(props);

        const hasSavedAddress = Boolean(props.account.shipping);

        const savedAddress = hasSavedAddress ?
            {
                fname: props.account.fname || '',
                lname: props.account.lname || '',
                ...props.account.shipping
            } : {};

        const values = {
            fname: '',
            lname: '',
            addressln1: '',
            addressln2: '',
            city: '',
            state: '',
            postal: '',
            ...savedAddress
        };

        this.state = {
            loading: false,
            open: false,
            hasSavedAddress,
            savedAddress: values,
            errors: null,
            inputErrors: {},
            values
        }

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.scrollToErrors = this.scrollToErrors.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onOpen() {
        const { savedAddress } = this.state;

        this.setState({
            open: true,
            errors: null,
            inputErrors: {},
            values: savedAddress
        });
    }

    onClose() {
        this.setState({ open: false });
    }

    handleChange(e) {
        if (e.target) {
            const { name, value } = e.target;

            this.setState(
                state => ({
                    values: {
                        ...state.values,
                        [name]: value
                    },
                    inputErrors: {
                        ...state.inputErrors,
                        [name]: null
                    }
                }),
                this.updateCheckoutData
            );
        }
    }

    scrollToErrors() {
        const ele = document.getElementById('shipping_errors');

        if (ele) {
            ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({ errors: null });

        const required = [
            {
                key: 'fname',
                label: 'First Name'
            },
            {
                key: 'lname',
                label: 'Last Name'
            },
            {
                key: 'addressln1',
                label: 'Address'
            },
            {
                key: 'city',
                label: 'City'
            },
            {
                key: 'state',
                label: 'State'
            },
            {
                key: 'postal',
                label: 'ZIP Code'
            }
        ];

        const inputErrors = {};

        for (let i = 0; i < required.length; i++) {
            const x = required[i];

            if (x.ignore) continue;

            if (!this.state.values[x.key]) {
                inputErrors[x.key] = `${x.label} Required`;
            }
        }

        const errKeys = Object.keys(inputErrors);

        if (errKeys.length) {
            const firstKey = errKeys[0];

            const ele = document.getElementById(firstKey);

            if (ele) {
                ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }

            this.setState({ inputErrors });
            return false;
        }

        this.setState({ loading: true });

        const {
            fname,
            lname,
            addressln1,
            addressln2,
            city,
            state,
            postal
        } = this.state.values;

        const address = {
            fname,
            lname,
            addressln1,
            addressln2,
            city,
            state,
            postal
        };

        const setAddressResult = await this.props.setShippingAddress(address);

        if (!setAddressResult.ok) {
            this.setState(
                {
                    loading: false,
                    errors: [
                        'Sorry, an error occurred.',
                        'Please try again.'
                    ]
                },
                this.scrollToErrors
            );

            return false;
        }

        await this.props.getAccountDetails();

        this.setState({
            loading: false,
            open: false,
            hasSavedAddress: true,
            savedAddress: address
        });

        return true;
    }

    formatShippingAddress() {
        const { fname, lname, shipping } = this.props.account;

        if (!shipping) {
            return 'None';
        }

        const {
            addressln1,
            addressln2,
            city,
            state,
            postal
        } = shipping;

        return (
            <>
                <span>{`${fname} ${lname}`}</span><br />
                <span>{`${addressln1}${addressln2 ? ' ' + addressln2 : ''}`}</span><br />
                <span>{`${city}, ${state} ${postal}`}</span>
            </>
        );
    }

    render() {
        const {
            loading,
            open,
            errors,
            inputErrors,
            values
        } = this.state;

        return (
            <>
                <section className={styles.accountItem}>
                    <h5>Shipping Address</h5>
                    <div className={styles.form}>
                        <p>{this.formatShippingAddress()}</p>
                        <Button
                            type='button'
                            color='secondary'
                            variant='outlined'
                            size='small'
                            onClick={this.onOpen}
                        >
                            CHANGE
                        </Button>
                    </div>
                </section>
                <Dialog
                    open={open}
                    onClose={this.onClose}
                    fullWidth={true}
                    maxWidth='sm'
                    aria-labelledby='edit_shipping_dialog_title'
                    classes={{ paper: styles.dialog }}
                >
                    <IconButton
                        aria-label='close'
                        size='small'
                        className={styles.close}
                        onClick={this.onClose}>
                        <X />
                    </IconButton>
                    <DialogTitle
                        id='edit_shipping_dialog_title'
                        disableTypography={true}
                        classes={{ root: styles.title }}
                    >
                        <h6>Shipping Address</h6>
                    </DialogTitle>
                    <DialogContent
                        classes={{ root: styles.content }}
                    >
                        <form
                            id='edit_shipping_form'
                            noValidate
                            className={styles.form}
                            onSubmit={this.onSubmit}
                        >
                            <section className={styles.section}>
                                {errors &&
                                    <ul id='shipping_errors' className={styles.errors}>
                                        {errors.map((x, i) => (
                                            <li key={i}>{x}</li>
                                        ))}
                                    </ul>
                                }
                                <div className={styles.row2}>
                                    <div className={styles.cell}>
                                        <TextField
                                            id='fname'
                                            name='fname'
                                            label={inputErrors['fname'] || 'First Name'}
                                            error={Boolean(inputErrors['fname'])}
                                            type='text'
                                            autoComplete='given-name'
                                            value={values.fname}
                                            onChange={this.handleChange}
                                            margin='dense'
                                            variant='outlined'
                                            fullWidth={true}
                                            className={styles.input}
                                        />
                                    </div>
                                    <div className={styles.cell}>
                                        <TextField
                                            id='lname'
                                            name='lname'
                                            label={inputErrors['lname'] || 'Last Name'}
                                            error={Boolean(inputErrors['lname'])}
                                            type='text'
                                            autoComplete='family-name'
                                            value={values.lname}
                                            onChange={this.handleChange}
                                            margin='dense'
                                            variant='outlined'
                                            fullWidth={true}
                                            className={styles.input}
                                        />
                                    </div>
                                </div>
                                <TextField
                                    id='addressln1'
                                    name='addressln1'
                                    label={inputErrors['addressln1'] || 'Address'}
                                    error={Boolean(inputErrors['addressln1'])}
                                    type='text'
                                    autoComplete='address-line1'
                                    value={values.addressln1}
                                    onChange={this.handleChange}
                                    margin='dense'
                                    variant='outlined'
                                    fullWidth={true}
                                    className={styles.input}
                                />
                                <TextField
                                    id='addressln2'
                                    name='addressln2'
                                    label={inputErrors['addressln2'] || 'Apartment, suite, etc. (optional)'}
                                    error={Boolean(inputErrors['addressln2'])}
                                    type='text'
                                    autoComplete='address-line2'
                                    value={values.addressln2}
                                    onChange={this.handleChange}
                                    margin='dense'
                                    variant='outlined'
                                    fullWidth={true}
                                    className={styles.input}
                                />
                                <TextField
                                    id='city'
                                    name='city'
                                    label={inputErrors['city'] || 'City'}
                                    error={Boolean(inputErrors['city'])}
                                    type='text'
                                    autoComplete='address-level2'
                                    value={values.city}
                                    onChange={this.handleChange}
                                    margin='dense'
                                    variant='outlined'
                                    fullWidth={true}
                                    className={styles.input}
                                />
                                <div className={styles.row2}>
                                    <div className={styles.cell}>
                                        <NativeSelect
                                            id='state'
                                            name='state'
                                            label={inputErrors['state'] || 'State'}
                                            error={Boolean(inputErrors['state'])}
                                            autoComplete='address-level1'
                                            value={values.state}
                                            className={styles.input}
                                            onChange={this.handleChange}
                                        >
                                            <option value='' />
                                            <option value='AL'>Alabama</option>
                                            <option value='AK'>Alaska</option>
                                            <option value='AZ'>Arizona</option>
                                            <option value='AR'>Arkansas</option>
                                            <option value='CA'>California</option>
                                            <option value='CO'>Colorado</option>
                                            <option value='CT'>Connecticut</option>
                                            <option value='DE'>Delaware</option>
                                            <option value='DC'>District Of Columbia</option>
                                            <option value='FL'>Florida</option>
                                            <option value='GA'>Georgia</option>
                                            <option value='HI'>Hawaii</option>
                                            <option value='ID'>Idaho</option>
                                            <option value='IL'>Illinois</option>
                                            <option value='IN'>Indiana</option>
                                            <option value='IA'>Iowa</option>
                                            <option value='KS'>Kansas</option>
                                            <option value='KY'>Kentucky</option>
                                            <option value='LA'>Louisiana</option>
                                            <option value='ME'>Maine</option>
                                            <option value='MD'>Maryland</option>
                                            <option value='MA'>Massachusetts</option>
                                            <option value='MI'>Michigan</option>
                                            <option value='MN'>Minnesota</option>
                                            <option value='MS'>Mississippi</option>
                                            <option value='MO'>Missouri</option>
                                            <option value='MT'>Montana</option>
                                            <option value='NE'>Nebraska</option>
                                            <option value='NV'>Nevada</option>
                                            <option value='NH'>New Hampshire</option>
                                            <option value='NJ'>New Jersey</option>
                                            <option value='NM'>New Mexico</option>
                                            <option value='NY'>New York</option>
                                            <option value='NC'>North Carolina</option>
                                            <option value='ND'>North Dakota</option>
                                            <option value='OH'>Ohio</option>
                                            <option value='OK'>Oklahoma</option>
                                            <option value='OR'>Oregon</option>
                                            <option value='PA'>Pennsylvania</option>
                                            <option value='RI'>Rhode Island</option>
                                            <option value='SC'>South Carolina</option>
                                            <option value='SD'>South Dakota</option>
                                            <option value='TN'>Tennessee</option>
                                            <option value='TX'>Texas</option>
                                            <option value='UT'>Utah</option>
                                            <option value='VT'>Vermont</option>
                                            <option value='VA'>Virginia</option>
                                            <option value='WA'>Washington</option>
                                            <option value='WV'>West Virginia</option>
                                            <option value='WI'>Wisconsin</option>
                                            <option value='WY'>Wyoming</option>
                                        </NativeSelect>
                                    </div>
                                    <div className={styles.cell}>
                                        <TextField
                                            id='postal'
                                            name='postal'
                                            label={inputErrors['postal'] || 'ZIP Code'}
                                            error={Boolean(inputErrors['postal'])}
                                            type='text'
                                            autoComplete='postal-code'
                                            value={values.postal}
                                            onChange={this.handleChange}
                                            margin='dense'
                                            variant='outlined'
                                            fullWidth={true}
                                            className={styles.input}
                                        />
                                    </div>
                                </div>
                            </section>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            form='edit_shipping_form'
                            type='submit'
                            color='secondary'
                            className={styles.save}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress
                                    color='inherit'
                                    size={18}
                                    className={styles.loading}
                                /> :
                                'SAVE CHANGES'
                            }
                        </Button>
                        <Button
                            color='default'
                            onClick={this.onClose}
                            disabled={loading}
                        >
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapState = (state) => ({
    account: state.account
});

const mapDispatch = {
    setShippingAddress: AccountActions.setShippingAddress,
    getAccountDetails: AccountActions.getAccountDetails
};

export default connect(mapState, mapDispatch)(EditShippingAddress);
