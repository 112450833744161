import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import IconButton from '@material-ui/core/IconButton';
import { X } from 'react-feather';
import moment from 'moment';
import clsx from 'clsx';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/my-account.module.scss';

class ManageSubscriptions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			open: false,
			subscription: null,
			reason: ''
		};

		this.onCancel = this.onCancel.bind(this);
		this.onClose = this.onClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onCancelSubmit = this.onCancelSubmit.bind(this);
		this.renderSubscription = this.renderSubscription.bind(this);
		this.onRenew = this.onRenew.bind(this);
	}

	onCancel(subscription) {
		this.setState({
			open: true,
			subscription: subscription.id,
			reason: ''
		});
	}

	onClose() {
		this.setState({ open: false });
	}

	handleChange(e) {
		this.setState({
			reason: e.target.value
		});
	}

	async onCancelSubmit(e) {
		e.preventDefault();

		this.setState({
			loading: true
		});

		const {
			subscription,
			reason
		} = this.state;

		await this.props.cancelSubscription(
			{
				subscription,
				reason
			}
		);

		await this.props.getAccountDetails();

		this.setState({
			loading: false,
			open: false
		});
	}

	async onRenew(subscription) {
		await this.props.renewSubscription(
			{
				subscription: subscription.id
			}
		);

		await this.props.getAccountDetails();
	}

	render() {
		const {
			loading,
			open,
			reason
		} = this.state;

		return (
			<>
				<section className={styles.accountItem}>
					<h5>Subscriptions</h5>
					<div className={styles.scroller}>
						<ul className={styles.sublist}>
							{this.renderSubscriptions()}
						</ul>
					</div>
				</section>
				<Dialog
					open={open}
					onClose={this.onClose}
					maxWidth='xs'
					fullWidth={true}
					aria-labelledby='cancellation_dialog_title'
					classes={{ paper: styles.dialog }}
				>
					<IconButton
						aria-label='close'
						size='small'
						className={styles.close}
						onClick={this.onClose}>
						<X />
					</IconButton>
					<DialogTitle
						id='cancellation_dialog_title'
						disableTypography={true}
						classes={{ root: styles.title }}
					>
						<h6>Sorry to see you go</h6>
					</DialogTitle>
					<DialogContent
						classes={{ root: styles.content }}
					>
						<form
							id='cancellation_form'
							noValidate
							className={styles.form}
							onSubmit={this.onCancelSubmit}
						>
							<p>Could you let us know how we can improve?</p>
							<TextareaAutosize
								placeholder='Reason for canceling (optional)'
								rows={4}
								rowsMax={8}
								className={styles.textarea}
								value={reason}
								onChange={this.handleChange}
							/>
						</form>
					</DialogContent>
					<DialogActions>
						<Button
							form='cancellation_form'
							type='submit'
							color='primary'
							className={styles.save}
							disabled={loading}
						>
							{loading ?
								<CircularProgress
									color='inherit'
									size={18}
									className={styles.loading}
								/> :
								'CANCEL SUBSCRIPTION'
							}
						</Button>
						<Button
							color='default'
							onClick={this.onClose}
							disabled={loading}
						>
							CLOSE
                        </Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}

	renderSubscriptions() {
		const { user } = this.props.auth;
		const { subscriptions } = this.props.account;

		if (!subscriptions.length) {
			if (user.external_marketplace) {
				return (
					<li className={styles.empty}>Make changes to your subscription on {user.external_marketplace}</li>
				);
			}

			return (
				<li className={styles.empty}>You have no active subscriptions</li>
			);
		}

		return subscriptions.map(this.renderSubscription);
	}

	renderSubscription(subscription) {
		const {
			id,
			//canceled_at,
			cancel_at_period_end,
			current_period_end,
			//current_period_start,
			//latest_invoice,
			//metadata,
			plan,
			//start_date,
			status
		} = subscription;

		// const {
		//     earrings,
		//     ring_size
		// } = metadata;

		// const details = [];

		// if (typeof (earrings) !== 'undefined') {
		//     details.push({
		//         label: 'Earrings',
		//         value: earrings === 'true' ? 'Yes' : 'No'
		//     });
		// }

		// if (typeof (ring_size) !== 'undefined') {
		//     details.push({
		//         label: 'Ring Size',
		//         value: ring_size
		//     });
		// }

		let status_text;
		let status_green = false;

		if (cancel_at_period_end) {
			status_text = 'Canceled';
		} else {
			switch (status) {
				case 'incomplete':
					status_text = 'Incomplete';
					break;

				case 'incomplete_expired':
					status_text = 'Incomplete (Expired)';
					break;

				case 'trialing':
					status_text = 'Trialing';
					status_green = true;
					break;

				case 'active':
					status_text = 'Active';
					status_green = true;
					break;

				case 'past_due':
					status_text = 'Past Due';
					break;

				case 'canceled':
					status_text = 'Canceled';
					break;

				case 'unpaid':
					status_text = 'Unpaid';
					break;

				default:
					status_text = status;
					break;
			}
		}

		const canceled = Boolean(cancel_at_period_end || status === 'canceled');

		let DateText = null;

		if (current_period_end) {
			const period_end_moment = moment.unix(current_period_end);
			const now = moment();

			if (period_end_moment.isAfter(now)) {
				DateText = () => (
					<div className={styles.date}>{canceled ? 'Ends' : 'Renews'} on < br /> {period_end_moment.format('M/D/YY')}</div>
				);
			}
		}

		const ActionButton = () => {
			const [loading, setLoading] = useState(false);

			if (canceled) {
				const handleRenew = async (e) => {
					setLoading(true);
					await this.onRenew(subscription);
				};

				return (
					<Button
						type='button'
						variant='text'
						color='secondary'
						size='small'
						onClick={handleRenew}
						disabled={!canceled || loading}
					>
						{loading ?
							<CircularProgress
								color='inherit'
								size={18}
								className={styles.loading}
							/> :
							'RENEW'
						}
					</Button>
				);
			}

			const handleCancel = (e) => {
				this.onCancel(subscription);
			};

			return (
				<Button
					type='button'
					variant='text'
					color='primary'
					size='small'
					onClick={handleCancel}
					disabled={canceled}
				>
					Cancel
                </Button>
			);
		}

		return (
			<li key={id}>
				<div className={styles.left}>
					<h6 className={styles.planName}>{plan.nickname}</h6>
					<div className={clsx(styles.status, status_green ? styles.green : styles.red)}>{status_text}</div>
					{/*
                    {Boolean(details.length > 0) &&
                        <div className={styles.details}>
                            {details.map(({ label, value }, i) => (
                                <small key={i}>{label}: {value}</small>
                            ))}
                        </div>
                    }
                    */}
				</div>
				{DateText &&
					<div className={styles.middle}>
						<DateText />
					</div>
				}
				<div className={styles.right}>
					<ActionButton />
				</div>
			</li>
		);
	}
}

const mapState = (state) => ({
	auth: state.auth,
	account: state.account
});

const mapDispatch = {
	getAccountDetails: AccountActions.getAccountDetails,
	cancelSubscription: AccountActions.cancelSubscription,
	renewSubscription: AccountActions.renewSubscription
};

export default connect(mapState, mapDispatch)(ManageSubscriptions);
