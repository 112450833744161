import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import clsx from 'clsx';
import { X } from 'react-feather';

import StripeElementWrapper from './stripe-element-wrapper';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/my-account.module.scss';

class EditDefaultCardInner extends Component {
    constructor(props) {
        super(props);

        const hasDefaultCard = Boolean(props.account.defaultCard);

        this.state = {
            loading: false,
            open: false,
            hasDefaultCard,
            errors: null
        };

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.scrollToErrors = this.scrollToErrors.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onOpen() {
        this.setState({
            open: true,
            errors: null
        });
    }

    onClose() {
        this.setState({ open: false });
    }

    scrollToErrors() {
        const ele = document.getElementById('payment_errors');

        if (ele) {
            ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    formatDefaultCard() {
        const { defaultCard } = this.props.account;

        if (!defaultCard) {
            return 'None';
        }

        const {
            brand,
            last4
        } = defaultCard;

        return (
            <span>{`${brand} ending in ${last4}`}</span>
        );
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            loading: true,
            errors: null
        });

        const tokenize = await this.props.stripe.createToken();

        if (tokenize.error) {
            this.setState(
                {
                    loading: false,
                    errors: [tokenize.error.message]
                },
                this.scrollToErrors
            );

            return false;
        }

        const token = tokenize.token;

        const result = await this.props.setDefaultSource({ token });

        const { ok, error } = result;

        if (ok) {
            await this.props.getAccountDetails();

            this.setState({
                loading: false,
                open: false,
                hasDefaultCard: true
            });

            return true;
        }

        const message = error && error.message ? error.message : 'Sorry, an error occurred.';

        this.setState(
            {
                loading: false,
                errors: [
                    message,
                    'Please try again.'
                ]
            },
            this.scrollToErrors
        );

        return false;
    }

    render() {
        const {
            loading,
            open,
            errors
        } = this.state;

        return (
            <>
                <section className={styles.accountItem}>
                    <h5>Payment Method</h5>
                    <div className={styles.form}>
                        <p>{this.formatDefaultCard()}</p>
                        <Button
                            type='button'
                            color='secondary'
                            variant='outlined'
                            size='small'
                            onClick={this.onOpen}
                        >
                            CHANGE
                    </Button>
                    </div>
                </section>
                <Dialog
                    open={open}
                    onClose={this.onClose}
                    fullWidth={true}
                    maxWidth='sm'
                    aria-labelledby='edit_card_dialog_title'
                    classes={{ paper: clsx(styles.dialog, styles.card) }}
                >
                    <IconButton
                        aria-label='close'
                        size='small'
                        className={styles.close}
                        onClick={this.onClose}>
                        <X />
                    </IconButton>
                    <DialogTitle
                        id='edit_card_dialog_title'
                        disableTypography={true}
                        classes={{ root: styles.title }}
                    >
                        <h6>Payment Method</h6>
                    </DialogTitle>
                    <DialogContent
                        classes={{ root: styles.content }}
                    >
                        <form
                            id='edit_card_form'
                            noValidate
                            className={styles.form}
                            onSubmit={this.onSubmit}
                        >
                            <section className={styles.section}>
                                {errors &&
                                    <ul id='shipping_errors' className={styles.errors}>
                                        {errors.map((x, i) => (
                                            <li key={i}>{x}</li>
                                        ))}
                                    </ul>
                                }
                                <StripeElementWrapper
                                    id='card_number'
                                    label='Card Number'
                                    placeholder='1234 1234 1234 1234'
                                    className={clsx(styles.input, styles.mb)}
                                    icon={true}
                                    Component={CardNumberElement}
                                />
                                <div className={clsx(styles.row2, styles.mobilefw)}>
                                    <div className={styles.cell}>
                                        <StripeElementWrapper
                                            id='card_expiry'
                                            label='Expiration (MM / YY)'
                                            placeholder='06 / 24'
                                            className={styles.input}
                                            Component={CardExpiryElement}
                                        />
                                    </div>
                                    <div className={styles.cell}>
                                        <StripeElementWrapper
                                            id='card_cvc'
                                            label='Security Code (CVC)'
                                            placeholder='123'
                                            className={styles.input}
                                            tooltip='A 3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front.'
                                            Component={CardCvcElement}
                                        />
                                    </div>
                                </div>
                            </section>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            form='edit_card_form'
                            type='submit'
                            color='secondary'
                            className={styles.save}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress
                                    color='inherit'
                                    size={18}
                                    className={styles.loading}
                                /> :
                                'SAVE CHANGES'
                            }
                        </Button>
                        <Button
                            color='default'
                            onClick={this.onClose}
                            disabled={loading}
                        >
                            CLOSE
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapState = (state) => ({
    account: state.account
});

const mapDispatch = {
    setDefaultSource: AccountActions.setDefaultSource,
    getAccountDetails: AccountActions.getAccountDetails
};

export default connect(mapState, mapDispatch)(
    injectStripe(EditDefaultCardInner)
);
